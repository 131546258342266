import React, { useEffect, useState } from 'react';
import axios from '../api/axios';
import { useSelector } from 'react-redux';
import CompanyInfo from './CompanyInfo';
import { ServerIcon } from '@heroicons/react/24/outline';
import TablePreloader from './UI/TablePreloader';

const CompanyInfoPage = () => {
  const companyIdentifier = useSelector(
    state => state.users.currentUser.companyIdentifier,
  );
  const [companyInfo, setCompanyInfo] = useState({});

  const fetchCompanyInfo = () => {
    axios
      .get(`/api/company-info/${companyIdentifier}`)
      .then(response => setCompanyInfo(response.data))
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCompanyInfo();
  }, []);

  const changeCompanyInfoHandler = (name, value) => {
    const companyInfoCopy = { ...companyInfo };
    companyInfoCopy[name] = value;
    setCompanyInfo(companyInfoCopy);
  };

  const saveCompanyInfoHandler = () => {
    axios
      .put(`/api/company-info/${companyIdentifier}`, companyInfo)
      .then(response => console.log(response))
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      {companyInfo ? (
        <>
          <CompanyInfo
            companyInfo={companyInfo}
            changeCompanyInfoHandler={changeCompanyInfoHandler}
          />
          <div className='container px-6 mx-auto max-w-7xl'>
            <div className='flex mt-4 justify-end'>
              <button
                type='button'
                className='flex items-center inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
                onClick={saveCompanyInfoHandler}
              >
                <ServerIcon className='w-6 h-6 mr-3' />
                Зберегти
              </button>
            </div>
          </div>
        </>
      ) : (
        <TablePreloader />
      )}
    </div>
  );
};

export default CompanyInfoPage;
