import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import CompanyInfo from '../CompanyInfo';
import { changeCompanyInfo } from '../../store/orderReducer';

const Step1 = () => {
  const step = useSelector(state => state.steps.step);
  const companyInfo = useSelector(state => state.order.companyInfo);

  const dispatch = useDispatch();

  const changeCompanyInfoHandler = (key, value) => {
    dispatch(changeCompanyInfo([key, value]));
  };

  return (
    <div className={step === 1 ? 'block' : 'hidden'}>
      <div
        className='flex w-full mb-4 rounded-lg bg-orange-100 p-3 text-base'
        role='alert'
      >
        <div className='flex items-center justify-center'>
          <InformationCircleIcon className='w-6 h-6 mr-4' />
        </div>
        <div>Оновіть дані закладу</div>
      </div>
      <CompanyInfo
        companyInfo={companyInfo}
        changeCompanyInfoHandler={changeCompanyInfoHandler}
      />
    </div>
  );
};

export default Step1;
