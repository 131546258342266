import axios from 'axios';

export const exportPdf = (url, payload, pdfName, changeLoadingDocument) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });

  instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });

  changeLoadingDocument(pdfName, true);

  instance
    .post(url, payload)
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', pdfName + '.pdf');
      document.body.appendChild(link);
      console.log(link);
      link.click();
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      changeLoadingDocument(pdfName, false);
    });
};
