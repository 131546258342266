import React, { useEffect, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import ModalAreYouSure from './ModalAreYouSure';
import axios from '../../api/axios';
import { MDBInput, MDBRipple } from 'mdb-react-ui-kit';
import * as PropTypes from 'prop-types';
import Selector from '../UI/Selector';

function MDBSelect(props) {
  return null;
}

MDBSelect.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onOptionSelect: PropTypes.func,
  label: PropTypes.string,
};
const OrderList = ({
  calculations,
  addNewCalculation,
  changeOrderValue,
  newOrder,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (categories.length === 0) {
      axios
        .get('/api/get-categories')
        .then(response => setCategories(response.data))
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  return (
    <>
      {calculations.length === 0 ? (
        <div
          className='flex w-max mb-4 rounded-lg bg-blue-100 px-3 py-2 text-base text-warning-800'
          role='alert'
        >
          <div className='flex items-center justify-center'>
            <InformationCircleIcon className='w-5 h-5 mr-3' />
          </div>
          <div className='text-sm'>
            Перелік послуг для розрахунку пустий. Додайте розрахунок з шаблонів
            або створіть новий
          </div>
        </div>
      ) : (
        <div className=''>
          <div className='font-bold mb-3'>Перелік вибраних розрахунків:</div>
          <ol className='px-8 list-decimal'>
            {calculations.map((order, index) => (
              <li key={order.templateId} className=''>
                <div className='flex gap-x-2'>
                  <div className='w-2/3 relative mb-3'>
                    <MDBInput
                      type='text'
                      id={'InputText' + order.templateId}
                      label='Назва послуги'
                      value={order.templateName}
                      onChange={e =>
                        changeOrderValue(index, 'templateName', e.target.value)
                      }
                    />
                  </div>
                  <div className='w-1/3'>
                    <Selector
                      options={categories}
                      placeholder='Категорія'
                      value={categories.find(
                        category => category.value === order.categoryId,
                      )}
                      onChange={e =>
                        changeOrderValue(index, 'categoryId', e.value)
                      }
                    />
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </div>
      )}
      <div className='flex justify-start'>
        <button
          type='button'
          className='inline-block rounded bg-success px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
          onClick={addNewCalculation}
        >
          Додати розрахунок без шаблону
        </button>
      </div>
      {calculations.length === 0 ? (
        ''
      ) : (
        <div className='flex justify-end items-center'>
          <div className='font-bold mr-1'>Сума до сплати:</div>
          <div className='text-lg font-bold'>{calculations.length * 240}</div>
          <div className='font-bold mx-1'>грн.</div>
          <MDBRipple rippleColor='white'>
            <button
              type='button'
              className='
                          flex
                        max-12
                        items-center
                        rounded
                        bg-primary
                        px-6
                        pb-2
                        mt-3
                        pt-2.5
                        ml-2
                        text-xs
                        font-medium
                        uppercase
                        leading-normal
                        text-white
                        shadow-[0_4px_9px_-4px_#3b71ca]
                        transition duration-150
                        ease-in-out
                        hover:bg-primary-600
                        hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]
                        focus:bg-primary-600
                        focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]
                        focus:outline-none
                        focus:ring-0
                        active:bg-primary-700
                        active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]
                        dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)]
                        dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]
                        dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]
                        dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]
                        disabled:opacity-70
                          '
              onClick={() => setShowModal(true)}
            >
              Підтвердити замовлення
            </button>
          </MDBRipple>
        </div>
      )}
      <ModalAreYouSure
        showModal={showModal}
        setShowModal={setShowModal}
        newOrder={newOrder}
      />
    </>
  );
};

export default OrderList;
