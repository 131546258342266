import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import 'react-toastify/dist/ReactToastify.css';
import {
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBRipple,
} from 'mdb-react-ui-kit';
import { changeCalculationThirdLevelValue } from '../../store/orderReducer';
import Selector from '../UI/Selector';

const ModalAddCalculationToTemplate = ({
  showModal,
  setShowModal,
  calculationForAdding,
}) => {
  const [categories, setCategories] = useState('');
  const [category, setCategory] = useState('');
  const [templateName, setTemplateName] = useState(calculationForAdding.name);

  const addCalculationToTemplateHandler = () => {
    axios.post('/api/calculations/add-to-template', {
      templateName,
      calculationIdentifier: calculationForAdding.identifier,
      categoryId: category.value,
    });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    axios.get('/api/get-categories').then(response => {
      if (response.data) {
        setCategories(response.data);
      }
    });
  };

  return (
    <MDBModal open={showModal} onClose={setShowModal}>
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            <h5 className='text-xl font-medium'>
              Додати калькуляцію "{calculationForAdding.name}" до шаблону
            </h5>
            <button
              type='button'
              className='box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none'
              onClick={() => setShowModal(false)}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='h-6 w-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBInput
              label='Назва шаблону'
              className='mb-2'
              value={templateName}
              onChange={e => setTemplateName(e.target.value)}
            />
            {categories.length > 0 && (
              <Selector
                options={categories}
                className='mt-2'
                placeholder='Категорія шаблону'
                value={category}
                onChange={e => setCategory(e)}
              />
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBRipple rippleColor='light'>
              <button
                type='button'
                className='inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200'
                onClick={() => setShowModal(false)}
              >
                Скасувати
              </button>
            </MDBRipple>
            <MDBRipple rippleColor='light'>
              <button
                type='button'
                className='ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
                onClick={addCalculationToTemplateHandler}
              >
                Додати
              </button>
            </MDBRipple>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default ModalAddCalculationToTemplate;
