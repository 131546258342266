import React, { useEffect, useState } from 'react';
import CalculationsTable from '../CalculationList/CalculationsTable';
import TablePreloader from '../UI/TablePreloader';
import axios from '../../api/axios';
import Selector from '../UI/Selector';
import Skeleton from 'react-loading-skeleton';
import AdminPanelTabs from './AdminPanelTabs';

const Calculations = () => {
  const [companyCalculations, setCompanyCalculations] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCompanies = () => {
    axios.get('/api/get-companies-list').then(response => {
      if (response.data) {
        setCompanyList(response.data);
      }
    });
  };

  const fetchCompanyCalculations = companyIdentifier => {
    setIsLoading(true);
    axios
      .get(`/api/company/${companyIdentifier}/calculations/calculations-list`)
      .then(response => {
        if (response.data) {
          setCompanyCalculations(response.data);
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (company) {
      fetchCompanyCalculations(company.value);
      setIsSelected(true);
    }
  }, [company]);

  return (
    <div className='container px-3 mx-auto max-w-7xl'>
      <AdminPanelTabs />
      <div className='w-100'>
        {companyList ? (
          <Selector
            placeholder='Виберіть компанію'
            options={companyList}
            value={company}
            onChange={setCompany}
          />
        ) : (
          <Skeleton height={24} />
        )}
      </div>
      <div className='flex flex-col mt-3'>
        <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
            <div className='overflow-hidden'>
              {isSelected && (
                <>
                  {!isLoading ? (
                    companyCalculations.length > 0 ? (
                      <CalculationsTable
                        companyCalculations={companyCalculations}
                        isAddToTemplate={true}
                      />
                    ) : (
                      <span>Калькуляції не були створені</span>
                    )
                  ) : (
                    <TablePreloader />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculations;
