import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import {
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import TablePreloader from '../UI/TablePreloader';
import { handleDateObjectConversion } from '../../utils/timeConversion';
import {
  STATUS_CONFIRMED,
  STATUS_CREATED,
  STATUS_FORMED,
  STATUS_PAYED,
  STATUS_SAVED,
  STATUS_SENT,
} from '../../constants/OrderStatus';
import ModalAddCalculationToTemplate from './ModalAddCalculationToTemplate';

const CalculationsTable = ({
  companyCalculations,
  isAddToTemplate = false,
}) => {
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [calculationForAdding, setCalculationForAdding] = useState(null);

  const translateStatus = status => {
    switch (status) {
      case STATUS_CREATED:
        return 'Сформована заявка на оплату';
        break;
      case STATUS_PAYED:
        return 'Оплачено. Можна починати заповнення';
        break;
      case STATUS_SAVED:
        return 'В процесі формування (чорновик)';
        break;
      case STATUS_FORMED:
        return 'Розраховано. Готово до відправки на узгодження';
        break;
      case STATUS_SENT:
        return 'Відправлено на узгодження';
        break;
      case STATUS_CONFIRMED:
        return 'Розрахованою і узгоджено';
        break;
      default:
        return 'Статус не визначено';
    }
  };

  return (
    <>
      <table className='min-w-full text-left text-sm font-light'>
        <thead className='border-b font-medium dark:border-neutral-500'>
          <tr>
            <th scope='col' className='px-6 py-2'>
              Код
            </th>
            <th scope='col' className='px-6 py-2'>
              Назва
            </th>
            <th scope='col' className='px-6 py-2'>
              Розраховано в замовленні
            </th>
            <th scope='col' className='px-6 py-2'>
              Статус
            </th>
            <th scope='col' className='px-6 py-2'>
              Створено
            </th>
            <th scope='col' className='px-6 py-2'>
              Оновлено
            </th>
            {isAddToTemplate && (
              <th scope='col' className='px-6 py-2'>
                Дії
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {companyCalculations.length > 0 ? (
            companyCalculations.map(calculation => (
              <tr
                key={calculation.id}
                className='border-b transition duration-300 ease-in-out hover:bg-gray-100 dark:border-neutral-500'
              >
                <td className='whitespace-nowrap px-6 py-2'>
                  {calculation.companyCode}
                </td>
                <td className='whitespace-nowrap px-6 py-2'>
                  {calculation.name}
                </td>
                <td className='whitespace-nowrap px-6 py-2'>
                  {calculation.orderName}
                </td>
                <td className='whitespace-nowrap px-6 py-2'>
                  {translateStatus(calculation.orderStatus)}
                </td>
                <td className='whitespace-nowrap px-6 py-2'>
                  {handleDateObjectConversion(calculation.created_at)}
                </td>
                <td className='whitespace-nowrap px-6 py-2'>
                  {handleDateObjectConversion(calculation.updated_at)}
                </td>
                {isAddToTemplate && (
                  <td className='whitespace-nowrap px-6 py-4 flex'>
                    <PlusCircleIcon
                      className='ml-2 w-6 h-6 text-primary cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
                      onClick={() => {
                        setCalculationForAdding(calculation);
                        setAddModalIsOpen(true);
                      }}
                    />
                  </td>
                )}
              </tr>
            ))
          ) : (
            <td className='whitespace-nowrap px-6 py-2'>
              Калькуляції в компанії відсутні
            </td>
          )}
        </tbody>
      </table>
      {addModalIsOpen && (
        <ModalAddCalculationToTemplate
          showModal={addModalIsOpen}
          setShowModal={setAddModalIsOpen}
          calculationForAdding={calculationForAdding}
        />
      )}
    </>
  );
};

export default CalculationsTable;
