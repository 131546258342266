import React from 'react';

const Home = () => {
  const handleSubmit = e => {
    e.preventDefault();
    // Логіка для обробки форми зворотнього зв'язку
  };

  return (
    <div className='bg-gray-100 text-gray-800'>
      <header className='py-6 px-4 text-center'>
        <h1 className='text-4xl font-bold my-8'>
          Розрахунок вартості послуг для вашого бізнесу
        </h1>
        <p className='text-lg my-6'>
          Ми - команда економістів та фахівців з фінансів, яка допомагає
          бізнесам ефективно розраховувати вартість послуг та оптимізувати
          витрати.
        </p>
        <p className='text-lg my-6'>
          Наш досвід та унікальні методики дозволяють вам отримати точні
          розрахунки вартості, що стають основою для стратегічних рішень вашого
          підприємства.
        </p>
      </header>
      <main className='px-4'>
        <div className='flex flex-wrap justify-around items-center my-12'>
          <div className='w-full md:w-1/2 md:pr-8 mb-6 md:mb-0 flex justify-center'>
            <img
              src='../images/logo_big.svg'
              alt='Logo'
              className='w-1/2 h-auto rounded-lg'
            />
          </div>
          <div className='w-full md:w-1/2 md:pl-8'>
            <h2 className='text-2xl font-bold mb-4'>Чому обирати нас?</h2>
            <p className='text-lg mb-4'>Ми пропонуємо:</p>
            <ul className='list-disc list-inside mb-6'>
              <li>Експертність у фінансовому аналізі</li>
              <li>Швидкі та точні розрахунки вартості</li>
              <li>Підтримка та індивідуальний підхід</li>
              <li>Послуги, оптимізовані під ваш бізнес</li>
              <li>Ефективні стратегії оптимізації витрат</li>
            </ul>
            <p className='text-lg mb-4'>
              Наша мета - допомогти вашому бізнесу збільшити ефективність та
              прибуток, а також знизити ризики та збільшити
              конкурентоспроможність.
            </p>
            <button className='bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-700'>
              Дізнатися більше
            </button>
          </div>
        </div>
        <div className='my-12'>
          <h2 className='text-3xl font-bold mb-6 text-center'>
            Відгуки наших клієнтів
          </h2>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
            {/* Додайте відгуки вашого клієнтів */}
            <div className='bg-white p-6 rounded-lg shadow-md'>
              <p className='mb-4'>
                "Дуже професійний підхід та відмінний результат. Рекомендую!"
              </p>
              <p className='text-right font-semibold'>- Ігор Ковальов, CEO</p>
            </div>
            <div className='bg-white p-6 rounded-lg shadow-md'>
              <p className='mb-4'>
                "Команда експертів, які допомогли нашому бізнесу ефективно
                оптимізувати витрати."
              </p>
              <p className='text-right font-semibold'>- Марія Степанова, CFO</p>
            </div>
          </div>
        </div>
        <div className='my-12 text-center'>
          <h2 className='text-3xl font-bold mb-6'>Маєте питання?</h2>
          <p className='text-lg mb-6'>
            Зв’яжіться з нашими експертами та отримайте консультацію
            безкоштовно.
          </p>
          <button className='bg-green-500 text-white px-6 py-3 rounded-lg hover:bg-green-700'>
            Зв’язатися зараз
          </button>
        </div>
      </main>
    </div>
  );
};

export default Home;
