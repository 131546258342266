import React, { useEffect, useState } from 'react';
import axios from '../../../api/axios';
import {
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import 'react-json-view-lite/dist/index.css';
import { handleDateObjectConversion } from '../../../utils/timeConversion';
import AdminPanelTabs from '../AdminPanelTabs';
import Paginator from '../../UI/Paginator';
import ModalAddPills from '../../Pills/ModalAddPills';
import ModalChangePills from '../../Pills/ModalChangePills';
import ModalDeletePill from '../../Pills/ModalDeletePills';
import ModalAddPillsForCompany from '../../Pills/ModalAddPillsForCompany';
import ModalAddPillToTemplate from '../../Pills/ModalAddPillToTemplate';
import { MDBInput } from 'mdb-react-ui-kit';

const AdminPills = () => {
  const [templatePills, setTemplatePills] = useState([]);
  const [templatePage, setTemplatePage] = useState(1);
  const [templatePerPage, setTemplatePerPage] = useState(10);
  const [templatePageCount, setTemplatePageCount] = useState(1);
  const [templateSearch, setTemplateSearch] = useState('');
  const [companyPills, setCompanyPills] = useState([]);
  const [companyPage, setCompanyPage] = useState(1);
  const [companyPerPage, setCompanyPerPage] = useState(10);
  const [companyPageCount, setCompanyPageCount] = useState(1);
  const [companySearch, setCompanySearch] = useState('');
  const [isAddCompanyPillsModalOpen, setIsAddCompanyPillsModalOpen] =
    useState(false);
  const [isAddTemplatePillsModalOpen, setIsAddTemplatePillsModalOpen] =
    useState(false);
  const [isChangeCompanyPillsModalOpen, setIsChangeCompanyPillsModalOpen] =
    useState(false);
  const [isChangeTemplatePillsModalOpen, setIsChangeTemplatePillsModalOpen] =
    useState(false);
  const [isDeleteCompanyPillsModalOpen, setIsDeleteCompanyPillsModalOpen] =
    useState(false);
  const [isDeleteTemplatePillsModalOpen, setIsDeleteTemplatePillsModalOpen] =
    useState(false);
  const [isAddPillToTemplateModalOpen, setIsAddPillToTemplateModalOpen] =
    useState(false);
  const [currentPill, setCurrentPill] = useState({});

  const fetchTemplatePills = () => {
    const url =
      templateSearch !== ''
        ? `/api/pills?page=${templatePage}&perPage=${templatePerPage}&search=${templateSearch}`
        : `/api/pills?page=${templatePage}&perPage=${templatePerPage}`;
    axios
      .get(url)
      .then(response => {
        setTemplatePills(response.data.data);
        setTemplatePage(response.data.meta.currentPage);
        setTemplatePageCount(response.data.meta.lastPage);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchCompanyPills = () => {
    const url =
      companySearch !== ''
        ? `/api/pills/companies?page=${companyPage}&perPage=${companyPerPage}&search=${companySearch}`
        : `/api/pills/companies?page=${companyPage}&perPage=${companyPerPage}`;
    axios
      .get(url)
      .then(response => {
        setCompanyPills(response.data.data);
        setCompanyPage(response.data.meta.currentPage);
        setCompanyPageCount(response.data.meta.lastPage);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchTemplatePills();
  }, [templatePage, templatePerPage]);

  useEffect(() => {
    fetchCompanyPills();
  }, [companyPage, companyPerPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchTemplatePills();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [templateSearch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchCompanyPills();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [companySearch]);

  const addTemplatePillsHandler = (name, measurementType, price) => {
    axios
      .post(`/api/pills`, {
        name: name,
        measurementType: measurementType,
        price: price,
      })
      .then(response => {
        fetchTemplatePills();
        setIsAddTemplatePillsModalOpen(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeTemplatePillsHandler = (id, name, measurementType, price) => {
    axios
      .put(`/api/pills/${id}`, {
        name: name,
        measurementType: measurementType,
        price: price,
      })
      .then(response => {
        fetchTemplatePills();
        setIsChangeTemplatePillsModalOpen(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteTemplatePillsHandler = id => {
    axios
      .delete(`/api/pills/${id}`)
      .then(response => {
        fetchTemplatePills();
        setIsDeleteTemplatePillsModalOpen(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsDeleteTemplatePillsModalOpen(false);
      });
  };

  const addCompanyPillsHandler = (
    companyIdentifier,
    name,
    measurementType,
    price,
  ) => {
    axios
      .post(`/api/company/${companyIdentifier}/pills`, {
        name: name,
        measurementType: measurementType,
        price: price,
      })
      .then(response => {
        fetchCompanyPills();
        setIsAddCompanyPillsModalOpen(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeCompanyPillsHandler = (id, name, measurementType, price) => {
    axios
      .put(`/api/company/${currentPill.companyIdentifier}/pills/${id}`, {
        name: name,
        measurementType: measurementType,
        price: price,
      })
      .then(response => {
        fetchCompanyPills();
        setIsChangeCompanyPillsModalOpen(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteCompanyPillsHandler = id => {
    axios
      .delete(`/api/company/${currentPill.companyIdentifier}/pills/${id}`)
      .then(response => {
        fetchCompanyPills();
        setIsDeleteCompanyPillsModalOpen(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsDeleteCompanyPillsModalOpen(false);
      });
  };

  const addNewPillToTemplateHandler = () => {
    axios
      .post(`/api/pills`, {
        name: currentPill.name,
        measurementType: currentPill.measurementType,
        price: currentPill.price,
      })
      .then(response => {
        fetchTemplatePills();
        fetchCompanyPills();
        setIsAddPillToTemplateModalOpen(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsAddPillToTemplateModalOpen(false);
      });
  };

  return (
    <div className='container px-3 mx-auto max-w-7xl'>
      <AdminPanelTabs />
      <div className='flex flex-col'>
        <div className='flex justify-content-between mt-2'>
          <h5 className='ms-4'>Затратні матеріали з шаблонів</h5>
          <div className='w-[200px]'>
            <MDBInput
              value={templateSearch}
              onChange={e => setTemplateSearch(e.target.value)}
              type='text'
              label='Пошук'
            />
          </div>
        </div>
        <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
            <div className='overflow-hidden'>
              <table className='min-w-full text-left text-sm font-light'>
                <thead className='border-b font-medium dark:border-neutral-500'>
                  <tr>
                    <th scope='col' className='px-6 py-1'>
                      ID
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Назва
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Од.виміру
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Ціна за од.виміру
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Створено
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Оновлено
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Дії
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {templatePills.map(pill => (
                    <tr
                      key={pill.id}
                      className='border-b transition duration-300 ease-in-out hover:bg-gray-100 dark:border-neutral-500'
                    >
                      <td className='px-6 py-1 font-medium'>{pill.id}</td>
                      <td className='px-6 py-1 max-w-[400px]'>{pill.name}</td>
                      <td className='px-6 py-1'>{pill.measurementType}</td>
                      <td className='px-6 py-1'>{pill.price}</td>
                      <td className='px-6 py-1'>
                        {handleDateObjectConversion(pill.created_at)}
                      </td>
                      <td className='px-6 py-1'>
                        {handleDateObjectConversion(pill.updated_at)}
                      </td>
                      <td className='px-6 py-1 flex'>
                        <TrashIcon
                          className='w-6 h-6 text-danger cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
                          onClick={() => {
                            setCurrentPill(pill);
                            setIsDeleteTemplatePillsModalOpen(true);
                          }}
                        />
                        <PencilSquareIcon
                          className='ml-2 w-6 h-6 text-success cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
                          onClick={() => {
                            setCurrentPill(pill);
                            setIsChangeTemplatePillsModalOpen(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={`flex justify-end mt-2`}>
              <button
                className='inline-block rounded bg-success px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
                onClick={() => setIsAddTemplatePillsModalOpen(true)}
              >
                Додати
              </button>
            </div>
            <Paginator
              pageCount={templatePageCount}
              setPage={setTemplatePage}
              perPage={templatePerPage}
              setPerPage={setTemplatePerPage}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className='flex flex-col'>
        <div className='flex justify-content-between mt-2'>
          <h5 className='ms-4'>Затратні матеріали інших компаній</h5>
          <div className='w-[200px]'>
            <MDBInput
              value={companySearch}
              onChange={e => setCompanySearch(e.target.value)}
              type='text'
              label='Пошук'
            />
          </div>
        </div>
        <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
            <div className='overflow-hidden'>
              <table className='min-w-full text-left text-sm font-light'>
                <thead className='border-b font-medium dark:border-neutral-500'>
                  <tr>
                    <th scope='col' className='px-6 py-1'>
                      ID
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Автор (компанія)
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Назва
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Од.виміру
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Ціна за од.виміру
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Створено
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Оновлено
                    </th>
                    <th scope='col' className='px-6 py-1'>
                      Дії
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {companyPills.map(pill => (
                    <tr
                      key={pill.id}
                      className='border-b transition duration-300 ease-in-out hover:bg-gray-100 dark:border-neutral-500'
                    >
                      <td className='px-6 py-1 font-medium'>{pill.id}</td>
                      <td className='px-6 py-1 font-medium'>{pill.company}</td>
                      <td className='px-6 py-1 max-w-[400px]'>{pill.name}</td>
                      <td className='px-6 py-1'>{pill.measurementType}</td>
                      <td className='px-6 py-1'>{pill.price}</td>
                      <td className='px-6 py-1'>
                        {handleDateObjectConversion(pill.created_at)}
                      </td>
                      <td className='px-6 py-1'>
                        {handleDateObjectConversion(pill.updated_at)}
                      </td>
                      <td className='px-6 py-1 flex'>
                        <TrashIcon
                          className='w-6 h-6 text-danger cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
                          onClick={() => {
                            setCurrentPill(pill);
                            setIsDeleteCompanyPillsModalOpen(true);
                          }}
                        />
                        <PencilSquareIcon
                          className='ml-2 w-6 h-6 text-success cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
                          onClick={() => {
                            setCurrentPill(pill);
                            setIsChangeCompanyPillsModalOpen(true);
                          }}
                        />

                        <PlusCircleIcon
                          className='ml-2 w-6 h-6 text-primary cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
                          onClick={() => {
                            setCurrentPill(pill);
                            setIsAddPillToTemplateModalOpen(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={`flex justify-end mt-2`}>
              <button
                className='inline-block rounded bg-success px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
                onClick={() => setIsAddCompanyPillsModalOpen(true)}
              >
                Додати
              </button>
            </div>
            <Paginator
              pageCount={companyPageCount}
              setPage={setCompanyPage}
              perPage={companyPerPage}
              setPerPage={setCompanyPerPage}
            />
          </div>
        </div>
      </div>
      {isAddTemplatePillsModalOpen && (
        <ModalAddPills
          showModal={isAddTemplatePillsModalOpen}
          setShowModal={setIsAddTemplatePillsModalOpen}
          addPillsHandler={addTemplatePillsHandler}
        />
      )}
      {isChangeTemplatePillsModalOpen && (
        <ModalChangePills
          showModal={isChangeTemplatePillsModalOpen}
          setShowModal={setIsChangeTemplatePillsModalOpen}
          currentPill={currentPill}
          changePillsHandler={changeTemplatePillsHandler}
        />
      )}
      {isDeleteTemplatePillsModalOpen && (
        <ModalDeletePill
          showModal={isDeleteTemplatePillsModalOpen}
          setShowModal={setIsDeleteTemplatePillsModalOpen}
          currentPill={currentPill}
          deletePillHandler={deleteTemplatePillsHandler}
        />
      )}
      {isAddCompanyPillsModalOpen && (
        <ModalAddPillsForCompany
          showModal={isAddCompanyPillsModalOpen}
          setShowModal={setIsAddCompanyPillsModalOpen}
          addPillsHandler={addCompanyPillsHandler}
        />
      )}
      {isChangeCompanyPillsModalOpen && (
        <ModalChangePills
          showModal={isChangeCompanyPillsModalOpen}
          setShowModal={setIsChangeCompanyPillsModalOpen}
          currentPill={currentPill}
          changePillsHandler={changeCompanyPillsHandler}
        />
      )}
      {isDeleteCompanyPillsModalOpen && (
        <ModalDeletePill
          showModal={isDeleteCompanyPillsModalOpen}
          setShowModal={setIsDeleteCompanyPillsModalOpen}
          currentPill={currentPill}
          deletePillHandler={deleteCompanyPillsHandler}
        />
      )}
      {isAddPillToTemplateModalOpen && (
        <ModalAddPillToTemplate
          showModal={isAddPillToTemplateModalOpen}
          setShowModal={setIsAddPillToTemplateModalOpen}
          currentPill={currentPill}
          addPillToTemplateHandler={addNewPillToTemplateHandler}
        />
      )}
    </div>
  );
};

export default AdminPills;
