import React, { useEffect, useState } from 'react';
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import TablePreloader from '../UI/TablePreloader';
import axios from '../../api/axios';
import { useSelector } from 'react-redux';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

const TemplateCalculations = ({ changeOrderList }) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [ownCalculations, setOwnCalculations] = useState([]);

  const companyIdentifier = useSelector(
    state => state.users.currentUser.companyIdentifier,
  );

  const fetchOwnCalculations = () => {
    axios
      .get(`/api/company/${companyIdentifier}/calculations`)
      .then(response => setOwnCalculations(response.data))
      .then(response => setIsDataLoaded(true))
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (ownCalculations.length === 0) {
      fetchOwnCalculations();
    }
  }, []);

  return (
    <>
      <div className='font-bold mt-8 mb-4'>
        Перелік власних розрахунків по категоріям:
      </div>
      {isDataLoaded ? (
        <>
          {ownCalculations.length > 0 ? (
            <MDBAccordion>
              {ownCalculations.map((category, index) => (
                <MDBAccordionItem
                  key={index}
                  collapseId={index + 2}
                  headerTitle={category.categoryName}
                >
                  {category.calculations.map(calculation => (
                    <div
                      key={calculation.calculationId}
                      className='mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]'
                    >
                      <input
                        className='relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] rounded-[0.25rem] border-[0.125rem] border-solid border-neutral-300 outline-none'
                        type='checkbox'
                        value=''
                        onClick={e =>
                          changeOrderList(
                            e,
                            category.categoryId,
                            'calculation',
                            calculation.calculationId,
                            calculation.name,
                          )
                        }
                        id={'checkbox' + calculation.calculationId}
                      />
                      <label
                        className='inline-block pl-[0.15rem] hover:cursor-pointer'
                        htmlFor={'checkbox' + calculation.calculationId}
                      >
                        {calculation.name}
                      </label>
                    </div>
                  ))}
                </MDBAccordionItem>
              ))}
            </MDBAccordion>
          ) : (
            <div
              className='flex w-max mb-4 rounded-lg bg-blue-100 px-3 py-2 text-base text-warning-800'
              role='alert'
            >
              <div className='flex items-center justify-center'>
                <InformationCircleIcon className='w-5 h-5 mr-3' />
              </div>
              <div className='text-sm'>
                Перелік власних розрахунків пустий. Створіть новий розрахунок
              </div>
            </div>
          )}
        </>
      ) : (
        <TablePreloader />
      )}
    </>
  );
};

export default TemplateCalculations;
