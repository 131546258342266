import React, { useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import OrderList from './OrderList';
import axios from '../../api/axios';
import { useSelector } from 'react-redux';
import {
  MDBAccordion,
  MDBAccordionItem,
  MDBInput,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
} from 'mdb-react-ui-kit';
import TablePreloader from '../UI/TablePreloader';
import TemplateCalculations from './TemplateCalculations';
import CompanyCalculations from './CompanyCalculations';

const CreateOrder = () => {
  const companyIdentifier = useSelector(
    state => state.users.currentUser.companyIdentifier,
  );
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}.${currentDate.getMonth() + 1}.${currentDate.getFullYear()}`;
  const [activeTab, setActiveTab] = useState('template');
  const [newOrder, setNewOrder] = useState({
    name: 'Розрахунок за: ' + formattedDate,
    companyInfo: {
      name: '',
      code: '',
      payment_code: '',
      form: '',
      ceo_name: '',
      ceo_phone: '',
      counter_name: '',
      counter_phone: '',
      address: '',
      medical_staff_salary_fund: '',
      supply_staff_salary_fund: '',
      food_service_staff_salary_fund: '',
      inpatient_stay_costs: '',
      additional_services_inpatient_stay_costs: '',
      food_expenses: '',
      additional_services_food_patients: '',
      other_direct_expenses: '',
      general_staff_salary_fund: '',
      operational_expenses: '',
      general_depreciation: '',
      other_general_expenses: '',
      administrative_staff_salary_fund: '',
      other_administrative_expenses: '',
      advertising_material_creation_and_placement_budget: '',
      rented_by_external_organizations_area: '',
      medical_service_rooms_area: '',
      patient_bedwards_area: '',
      kitchen_and_canteen_area: '',
      other_areas: '',
      unified_social_security_contribution_rate: '',
      actual_amount_for_leave_payment: '',
      total_number_of_beds: '',
    },
    calculations: [],
  });

  const fetchCompanyInfo = () => {
    axios
      .get(`/api/company-info/${companyIdentifier}`)
      .then(response =>
        setNewOrder(prevOrder => ({
          ...prevOrder,
          companyInfo: response.data,
        })),
      )
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCompanyInfo();
  }, []);

  const changeOrderName = name => {
    setNewOrder(prevOrder => ({
      ...prevOrder,
      name: name,
    }));
  };

  const changeOrderList = (e, categoryId, source, sourceId, templateName) => {
    if (e.target.checked) {
      setNewOrder(prevOrder => ({
        ...prevOrder,
        calculations: [
          ...prevOrder.calculations,
          { categoryId, source, sourceId, templateName },
        ],
      }));
    } else if (!e.target.checked) {
      setNewOrder(prevOrder => ({
        ...prevOrder,
        calculations: prevOrder.calculations.filter(
          item => item.sourceId !== sourceId,
        ),
      }));
    }
  };

  const addNewCalculation = () => {
    const newCalculationObj = {
      categoryId: null,
      source: null,
      sourceId: null,
    };

    setNewOrder(prevOrder => ({
      ...prevOrder,
      calculations: [...prevOrder.calculations, newCalculationObj],
    }));
  };

  const changeOrderValue = (index, name, value) => {
    let newOrderCopy = { ...newOrder };
    newOrderCopy.calculations[index][name] = value;
    setNewOrder(newOrderCopy);
  };

  return (
    <>
      <div className='container flex-1 px-6 mx-auto max-w-7xl'>
        <nav className='w-full rounded-md mb-3'>
          <ol className='list-reset flex'>
            <li>
              <Link
                to='/orders'
                className='text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600'
              >
                Розрахунок послуг
              </Link>
            </li>
            <li>
              <span className='mx-2 text-neutral-500 dark:text-neutral-400'>
                /
              </span>
            </li>
            <li className='text-neutral-500 dark:text-neutral-400'>
              Створити нове замовлення на розрахунок
            </li>
          </ol>
        </nav>
        <div className='flex'>
          <div
            className='flex w-full mb-4 rounded-lg bg-orange-100 p-3 text-base'
            role='alert'
          >
            <div className='flex items-center justify-center'>
              <ExclamationCircleIcon className='w-6 h-6 mr-3' />
            </div>
            <div>
              Виберіть шаблони по яким плануєте зробити розрахунки або створіть
              нові розрахунки
            </div>
          </div>
        </div>
        <div className='relative mb-3'>
          <MDBInput
            type='text'
            id='orderName'
            label='Назва розрахунку'
            value={newOrder.name}
            onChange={e => changeOrderName(e.target.value)}
          />
        </div>
        <OrderList
          calculations={newOrder.calculations}
          addNewCalculation={addNewCalculation}
          changeOrderValue={changeOrderValue}
          newOrder={newOrder}
        />
        <MDBTabs fill className='my-3'>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => {
                setActiveTab('template');
              }}
              active={activeTab === 'template'}
            >
              Шаблони розрахункців "Медицина ВМ"
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => {
                setActiveTab('company');
              }}
              active={activeTab === 'company'}
            >
              Шаблони з власних розрахунків компанії
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
        {activeTab === 'template' && (
          <TemplateCalculations changeOrderList={changeOrderList} />
        )}
        {activeTab === 'company' && (
          <CompanyCalculations changeOrderList={changeOrderList} />
        )}
      </div>
    </>
  );
};

export default CreateOrder;
