const defaultState = {
  currentUser: null,
};

const ADD_CURRENT_USER = 'ADD_CURRENT_USER';
const LOGOUT_USER = 'LOGOUT_USER';

export const usersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_CURRENT_USER:
      return { ...state, currentUser: action.payload };
    case LOGOUT_USER:
      return { ...state, currentUser: null };
    default:
      return state;
  }
};

export const addCurrentUserAction = payload => ({
  type: ADD_CURRENT_USER,
  payload,
});
export const logoutUserAction = payload => ({ type: LOGOUT_USER, payload });
