import axios from '../../api/axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import { addOrder } from '../../store/orderReducer';
import TailwindPresets from '../TailwindPresets/TailwindPresets';
import ModalCalculate from './ModalCalculate';

const ChangeOrder = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const { identifier } = useParams();

  useEffect(() => {
    axios
      .get(`/api/orders/${identifier}`)
      .then(response => {
        if (response.status === 200) {
          dispatch(addOrder(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const order = useSelector(state => state.order);

  const step = useSelector(state => state.steps.step);

  const setCurrentStep = step => {
    dispatch({ type: 'CHANGE_STEP', payload: step });
  };

  const saveCalculationList = () => {
    axios
      .post('/api/calculations/save', {
        identifier: order.identifier,
        name: order.name,
        calculations: JSON.stringify(order.calculations),
        companyInfo: JSON.stringify(order.companyInfo),
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className='container flex-1 px-6 mx-auto max-w-7xl'>
      <h1 className='text-xl font-bold mb-4'>Розрахувати нові послуги</h1>
      <div className='flex justify-between mb-4'>
        <button
          onClick={() => setCurrentStep(1)}
          className={
            step === 1
              ? 'flex-col bg-blue-100 p-2 rounded-xl'
              : 'flex-col p-2 hover:font-bold hover:scale-110 transition duration-500 ease-in-out'
          }
        >
          <div className='flex shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] w-8 h-8 mx-auto items-center justify-center rounded-full border border-1 border-gray-300 bg-gray-100'>
            1
          </div>
          <div className='text-xs min-w-max mx-2 mt-1.5'>Дані закладу</div>
        </button>
        <div className='w-full border-b border-gray-400 self-center border-gray-300' />
        <button
          onClick={() => setCurrentStep(2)}
          className={
            step === 2
              ? 'flex-col bg-blue-100 p-2 rounded-xl'
              : 'flex-col p-2 hover:font-bold hover:scale-110 transition duration-500 ease-in-out'
          }
        >
          <div className='flex shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] w-8 h-8 mx-auto items-center justify-center rounded-full border border-1 border-gray-300 bg-gray-100'>
            2
          </div>
          <div className='text-xs min-w-max mx-2 mt-1.5'>Часові витрати</div>
        </button>
        <div className='w-full border-b border-gray-400 self-center border-gray-300' />
        <button
          onClick={() => setCurrentStep(3)}
          className={
            step === 3
              ? 'flex-col bg-blue-100 p-2 rounded-xl'
              : 'flex-col p-2 hover:font-bold hover:scale-110 transition duration-500 ease-in-out'
          }
        >
          <div className='flex shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] w-8 h-8 mx-auto items-center justify-center rounded-full border border-1 border-gray-300 bg-gray-100'>
            3
          </div>
          <div className='text-xs min-w-max mx-2 mt-1.5'>
            Матеріальні витрати
          </div>
        </button>
        <div className='w-full border-b border-gray-400 self-center border-gray-300' />
        <button
          onClick={() => setCurrentStep(4)}
          className={
            step === 4
              ? 'flex-col bg-blue-100 p-2 rounded-xl'
              : 'flex-col p-2 hover:font-bold hover:scale-110 transition duration-500 ease-in-out'
          }
        >
          <div className='flex shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] w-8 h-8 mx-auto items-center justify-center rounded-full border border-1 border-gray-300 bg-gray-100'>
            4
          </div>
          <div className='text-xs min-w-max mx-2 mt-1.5'>
            Прайс-лист матеріалів
          </div>
        </button>
        <div className='w-full border-b border-gray-400 self-center border-gray-300' />
        <button
          onClick={() => setCurrentStep(5)}
          className={
            step === 5
              ? 'flex-col bg-blue-100 p-2 rounded-xl'
              : 'flex-col p-2 hover:font-bold hover:scale-110 transition duration-500 ease-in-out'
          }
        >
          <div className='flex shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] w-8 h-8 mx-auto items-center justify-center rounded-full border border-1 border-gray-300 bg-gray-100'>
            5
          </div>
          <div className='text-xs min-w-max mx-2 mt-1.5'>Рентабельність</div>
        </button>
        <div className='ms-4'>
          <button
            onClick={saveCalculationList}
            className={TailwindPresets.buttonInfo + ' w-full mb-2'}
          >
            Зберегти
          </button>
          <button
            onClick={() => {
              setShowModal(true);
            }}
            className={TailwindPresets.buttonSuccess}
          >
            Розрахувати
          </button>
        </div>
      </div>
      <Step1 />
      <Step2 />
      <Step3 />
      <Step4 />
      <Step5 />
      {/*/!*<Step6 />*!/*/}

      <ModalCalculate showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default ChangeOrder;
