import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InformationCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  changeCalculationThirdLevelValue,
  changePillInfoInCalculation,
} from '../../store/orderReducer';
import axios from '../../api/axios';
import calculation from '../OrdersList';
import { MDBInput } from 'mdb-react-ui-kit';
import TablePreloader from '../UI/TablePreloader';
import Selector from '../UI/Selector';

const Step4 = () => {
  const step = useSelector(state => state.steps.step);
  const calculations = useSelector(state => state.order.calculations);
  const [templateMeasurements, setTemplateMeasurements] = useState([]);
  const [companyMeasurements, setCompanyMeasurements] = useState([]);
  const [uniquePills, setUniquePills] = useState([]);
  const dispatch = useDispatch();

  const companyIdentifier = useSelector(
    state => state.users.currentUser.companyIdentifier,
  );

  const isAdmin = false;

  const calculate = () => {
    if (calculations && uniquePills) {
      const categories = Object.keys(calculations);
      const uniquePillsClone = [];

      for (let i = 0; i < categories.length; i++) {
        calculations[categories[i]].forEach(calculation => {
          calculation.calculation.pills.forEach(pill => {
            const existingPillIndex = uniquePillsClone.findIndex(
              pillFromList => pillFromList.id === pill.id,
            );

            if (existingPillIndex !== -1) {
              uniquePillsClone[existingPillIndex].quantityInList += 1;
              uniquePillsClone[existingPillIndex].spend += pill.spend;
            } else {
              uniquePillsClone.push({
                id: pill.id,
                measurement_type: pill.measurement_type,
                name: pill.name,
                price: pill.price,
                spend: pill.spend,
                quantityInList: 1,
              });
            }
          });
        });
      }

      setUniquePills(uniquePillsClone);
    }
  };

  useEffect(() => {
    calculate();
  }, [calculations]);

  useMemo(() => {
    axios
      .get('/api/get-measurements')
      .then(response => {
        setTemplateMeasurements(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useMemo(() => {
    axios
      .get(`/api/company/${companyIdentifier}/measurements/list`)
      .then(response => {
        setCompanyMeasurements(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const changeValueHandler = (id, key, value) => {
    dispatch(changePillInfoInCalculation([id, key, value]));
    calculate();
  };

  return (
    <div className={step === 4 ? 'block' : 'hidden'}>
      <div
        className='flex w-full mb-4 rounded-lg bg-orange-100 p-3 text-base'
        role='alert'
      >
        <div className='flex items-center justify-center'>
          <InformationCircleIcon className='w-6 h-6 mr-4' />
        </div>
        <div>
          <p className='mb-0'>Підкоригуйте ціни на матеріали</p>
          <p className='mb-0'>
            Нижче - перелік матеріалів які були внесені в розділі "Матеріальні
            затрати". Підкоригуйте ціни
          </p>
        </div>
      </div>
      {uniquePills ? (
        uniquePills.map((pill, pillIndex) => (
          <div
            key={pillIndex}
            className='flex items-center justify-center gap-x-4 mb-4'
          >
            <div className='w-6/12 flex items-center'>
              {isAdmin && <span className='mr-2'>{pill.quantityInList}x</span>}
              <div className='block w-full'>
                <MDBInput
                  type='text'
                  disabled={true}
                  label='Матеріал'
                  value={pill.name}
                />
              </div>
            </div>
            <div className='w-4/12'>
              <Selector
                options={templateMeasurements}
                secondOptions={companyMeasurements}
                placeholder='Од.виміру'
                value={{
                  label: pill.measurement_type,
                  value: pill.measurement_type,
                }}
                onChange={e =>
                  changeValueHandler(pill.id, 'measurement_type', e.value)
                }
              />
            </div>
            <div className='w-2/12'>
              <MDBInput
                type='number'
                id={'pill' + pill.id + 'Price'}
                label='Вартість'
                value={pill.price}
                onChange={e =>
                  changeValueHandler(pill.id, 'price', e.target.value)
                }
              />
            </div>
            {/*<div className="w-2/12">*/}
            {/*    <MDBInput*/}
            {/*        type="number"*/}
            {/*        id={"pill" + pill.id + "quantity"}*/}
            {/*        label="Кількість в одиниці"*/}
            {/*        value={pill.quantity}*/}
            {/*        onChange={(e) => changeValueHandler(pill.id, "quantity", e.target.value)}*/}
            {/*    />*/}
            {/*</div>*/}
            {isAdmin && (
              <>
                <div className='w-2/12'>
                  <MDBInput
                    type='text'
                    disabled={true}
                    label='Загальні витрати'
                    value={pill.spend}
                  />
                </div>
                <div className='w-36 text-center'>
                  <div className='text-sm'>Загальна ціна</div>
                  <div className='font-bold'>
                    {(pill.price * pill.spend).toFixed(2)}
                  </div>
                </div>
              </>
            )}
          </div>
        ))
      ) : (
        <TablePreloader />
      )}
    </div>
  );
};

export default Step4;
