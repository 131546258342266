import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import Selector from './Selector';

const Paginator = ({ pageCount, setPage, perPage, setPerPage }) => {
  return (
    <div className='flex justify-content-between w-100'>
      <Selector
        className='mt-4'
        value={{ label: perPage, value: perPage }}
        options={[
          { label: 5, value: 5 },
          { label: 10, value: 10 },
          { label: 15, value: 15 },
          { label: 20, value: 20 },
          { label: 25, value: 25 },
        ]}
        onChange={e => setPerPage(e.value)}
        position='top'
      />
      <ReactPaginate
        className='paginator'
        breakLabel='...'
        nextLabel='»'
        onPageChange={e => setPage(e.selected + 1)}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel='«'
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default Paginator;
