import React, { useEffect, useState } from 'react';
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import TablePreloader from '../UI/TablePreloader';
import axios from '../../api/axios';

const TemplateCalculations = ({ changeOrderList }) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [templates, setTemplates] = useState([]);

  const fetchTemplates = () => {
    axios
      .get('/api/get-templates-list')
      .then(response => setTemplates(response.data))
      .then(response => setIsDataLoaded(true))
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (templates.length === 0) {
      fetchTemplates();
    }
  }, []);

  return (
    <>
      <div className='font-bold mt-8 mb-4'>
        Перелік доступних шаблонів розрахунків по категоріям:
      </div>
      {isDataLoaded ? (
        <MDBAccordion>
          {templates.map((category, index) => (
            <MDBAccordionItem
              key={index}
              collapseId={index + 2}
              headerTitle={category.categoryName}
            >
              {category.templates.map(template => (
                <div
                  key={template.templateId}
                  className='mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]'
                >
                  <input
                    className='relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] rounded-[0.25rem] border-[0.125rem] border-solid border-neutral-300 outline-none'
                    type='checkbox'
                    value=''
                    onClick={e =>
                      changeOrderList(
                        e,
                        category.categoryId,
                        'template',
                        template.templateId,
                        template.templateName,
                      )
                    }
                    id={'checkbox' + template.templateId}
                  />
                  <label
                    className='inline-block pl-[0.15rem] hover:cursor-pointer'
                    htmlFor={'checkbox' + template.templateId}
                  >
                    {template.templateName}
                  </label>
                </div>
              ))}
            </MDBAccordionItem>
          ))}
        </MDBAccordion>
      ) : (
        <TablePreloader />
      )}
    </>
  );
};

export default TemplateCalculations;
