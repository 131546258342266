import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { logDOM } from '@testing-library/react';
import { MDBTabs, MDBTabsItem, MDBTabsLink } from 'mdb-react-ui-kit';

const Selector = ({
  className,
  options,
  placeholder,
  value,
  onChange,
  position = 'bottom',
  noOptionsText = 'Варіанти не знайдені',
  isWithSearch = false,
  secondOptions = null,
}) => {
  const [color, setColor] = useState('#bdbdbd');
  const [innerTemplateOptions, setInnerTemplateOptions] = useState(options);
  const [innerCompanyOptions, setInnerCompanyOptions] = useState(secondOptions);
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('template');
  const dropdownRef = useRef(null);

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setInnerTemplateOptions(options);
  }, [options]);

  useEffect(() => {
    setInnerCompanyOptions(secondOptions);
  }, [secondOptions]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleSearch = e => {
    const value = e.target.value.toLowerCase();

    const filteredTemplateOptions = options.filter(option =>
      option.label.toLowerCase().includes(value),
    );
    setInnerTemplateOptions(filteredTemplateOptions);

    const filteredCompanyOptions = secondOptions.filter(secondOption =>
      secondOption.label.toLowerCase().includes(value),
    );
    setInnerCompanyOptions(filteredCompanyOptions);
  };

  return (
    <div className={clsx(className, 'position-relative')} ref={dropdownRef}>
      {value && (
        <div
          style={{ color: 'rgba(0, 0, 0, .6)' }}
          className='bg-white text-xs position-absolute left-2 top-[-8px] px-1'
        >
          {placeholder}
        </div>
      )}
      <div
        style={{
          border: '1px solid #bdbdbd',
          borderRadius: '4px',
          padding: '5px 8px 5px 12px',
          maxHeight: '36px',
        }}
        className='flex justify-content-between'
        onClick={() => setIsOpen(!isOpen)}
      >
        {value ? value.label : placeholder}
        <div
          style={{ color }}
          className='flex align-items-center'
          onMouseEnter={() => setColor('#999')}
          onMouseLeave={() => setColor('#bdbdbd')}
        >
          <span className='mr-1 ml-2'>|</span>
          <svg
            height='20'
            width='20'
            viewBox='0 0 20 20'
            aria-hidden='true'
            focusable='false'
            className='css-tj5bde-Svg'
          >
            <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
          </svg>
        </div>
      </div>
      {isOpen && (
        <div
          style={{
            boxShadow:
              '0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)',
            boxSizing: 'border-box',
            maxHeight: '350px',
            top: position === 'top' ? '-164px' : '100%',
          }}
          className='position-absolute w-100 pb-1 bg-white rounded mt-1 z-40 overflow-y-scroll'
        >
          {isWithSearch && (
            <div className='sticky-top bg-white'>
              <input
                type='text'
                placeholder='Пошук...'
                className='focus:outline-none w-full px-3 py-2'
                onChange={handleSearch}
              />
              <hr className='my-0' />
            </div>
          )}
          {secondOptions && (
            <MDBTabs fill className='mb-3'>
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => {
                    setActiveTab('template');
                  }}
                  active={activeTab === 'template'}
                >
                  Шаблони
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => {
                    setActiveTab('company');
                  }}
                  active={activeTab === 'company'}
                >
                  Власні
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
          )}
          {activeTab === 'template' && (
            <>
              {innerTemplateOptions.length > 0 ? (
                innerTemplateOptions.map((option, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      onChange(option);
                      setIsOpen(false);
                    }}
                    className='px-3 cursor-pointer py-0.5 hover:bg-gray-100'
                  >
                    {option.label}
                  </div>
                ))
              ) : (
                <span className='ms-3'>{noOptionsText}</span>
              )}
            </>
          )}
          {activeTab === 'company' && (
            <>
              {innerCompanyOptions.length > 0 ? (
                innerCompanyOptions.map((option, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      onChange(option);
                      setIsOpen(false);
                    }}
                    className='px-3 cursor-pointer py-0.5 hover:bg-gray-100'
                  >
                    {option.label}
                  </div>
                ))
              ) : (
                <span className='ms-3'>{noOptionsText}</span>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Selector;
